






























import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";

import BaseComponent from "@/components/base-component.vue";
import SearchResults from "@/components/search-results/search-results.vue";
import Loader from "@/components/loader.vue";
import { SearchState } from "@/store/modules/search";
import { EventsState } from "@/store/modules/events";
import { CartState } from "@/store/modules/cart";
import { SettingsState } from "@/store/modules/settings";
import { ProductTypes } from "@/product-types";

@Component({
  name: "Search",
  components: {
    SearchResults,
    Loader,
  },
})
export default class Search extends BaseComponent {

  private readonly searchState: SearchState = getModule(SearchState, this.$store);
  private readonly eventsState: EventsState = getModule(EventsState, this.$store);
  private readonly cartState: CartState = getModule(CartState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  productTypes: any = ProductTypes;

  event: any = null;
  eventId: string = "";
  eventName: string = "";
  eventDate: string = "";
  isAllPhotosVisible: boolean = true;
  searchToken: any = null;
  recentSearch: any = null;
  runnerNum: string = "";
  runnerFrameValues: string = "";
  byFace: string = "";
  
  faceImage: string = "";
  selfieId: string = "";
  personId: string = "";
  
  photos: string = "[]";
  notFound: string = "";

  showResults: boolean = false;

  backPageData: any = null;

  get backPage(): string {
    return this.$store.state.goBack;
  }

  get newPage(): string {
    return this.$store.state.goPage;
  }

  get number(): string {
    return this.$route.params.number;
  }

  get paramId(): string {
    return this.$route.params.id;
  }

  get hasArgs(): boolean {
    return (this.paramId !== undefined && this.number !== undefined);
  }

  get showLoader(): boolean {
    return !this.hasArgs || !this.showResults;
  }

  get photosPerPage(): number {
    if (this.smOnly || this.mdOnly) return 12;
    return 50;
  }

  isStartNumber(id: string): boolean {
    if (!id) return false;
    if (id.indexOf("+") < 0) return true;
    return false;
  }

  @Watch("backPage")
  async goBack(page: string): Promise<void> {
    await this.setBackPageData(page);
  }

  @Watch("newPage")
  async goPage(page: string): Promise<void> {
    await this.setBackPageData(page);
  }

  async setBackPageData(page: string): Promise<void> {
    if (!page) return;

    this.backPageData = {
      name: page, 
      params: { 
        eventId: this.eventId, 
        eventName: this.eventName, 
        eventDate: this.eventDate, 
      } 
    };
  }

  async searchByTag(eventId: string, runnerNum: string): Promise<void> {
    const payloadForToken = {
      eventId,
      payload: {},
    };

    const payload: any = {
      eventId: eventId,
      token: '',
      pagination: { offset: 0, count: this.photosPerPage },
    };

    const searchData = {
      event: { ...this.event },
      search: {},
      date: Date.now(),
    };

    if (!this.isStartNumber(runnerNum)) {
      const num: string[] = runnerNum.split("+");
      if (num.length != 2) {
        await this.$router.push("/");
        return;
      }
      await this.searchState.loadBySelfieId({ selfieId: num[0] });
      if (this.searchState.searchError) {
        await this.$router.push("/");
        return;
      }
      if (num[1] === "undefined") {
        this.byFace = "true";
        await this.showNotFoundResults();
        return;
      }

      this.byFace = "true";
      const faceUrl = this.searchState.persons.length > 0 ? this.searchState.persons[0].faceUrl : "";
      searchData.search = { selfieId: num[0], personId: num[1], faceUrl: faceUrl };
      payloadForToken.payload = { personId: num[1] };
    } else {
      searchData.search = { startNumber: runnerNum };
      payloadForToken.payload = { startNumber: runnerNum };
    }

    const tokenResult: any = await this.searchState.getToken(payloadForToken);
    if (tokenResult.status !== 200) {
      await this.showNotFoundResults();
      return;
    }
    const token = this.searchState.searchToken ? this.searchState.searchToken.value : '';
    const complementable = this.searchState.searchToken ? this.searchState.searchToken.complementable === true : false;
    payload.token = token;
    searchData.search = { token, complementable, ...searchData.search };
    this.searchToken = this.searchState.searchToken ? this.searchState.searchToken : null;

    await this.searchState.searchByTag(payload);

    if (this.searchState.searchError) {
      await this.showNotFoundResults();
      return;
    }

    await this.saveRecentSearch(searchData);

    if (this.searchState.photos.length > 0) {
      await this.showFoundResults();
      // console.log('= found');
    } else {
      await this.showNotFoundResults();
      // console.log('= not found');
    }
  }

  async setEventParams(): Promise<void> {
    if (this.event === null) {
      this.$store.state.history = [];
      await this.$router.push("/");
      return;
    }

    this.eventId = this.event.eventId;
    this.eventName = this.event.title;
    this.eventDate = this.event.date.slice(0, 10);
    this.isAllPhotosVisible = this.event.allPhotosVisibility ? this.event.allPhotosVisibility === 'Visible' : true;
  }

  async showFoundResults(): Promise<void> {
    this.setEventParams();
    
    if (this.byFace === "true") {
      this.faceImage = this.searchState.persons.length > 0 ? this.searchState.persons[0].faceUrl : "";
      this.selfieId = this.searchState.selfieId;
      this.personId = this.searchState.persons.length > 0 ? this.searchState.persons[0].personId : "";
    } else {
      this.faceImage = "";
      this.selfieId = "";
      this.personId = "";
    }

    await this.setValuesForFrame();

    let tag: string = "";
    if (this.byFace === "true") {
      tag = `${this.selfieId}+${this.personId}`;
    } else {
      tag = `${this.number}`;
    }
    await this.settingsState.setMyPhotosLink(`/search/${this.event.externalEventId}/${tag}`);
    
    this.photos = JSON.stringify(this.searchState.photos);
    this.notFound = "false";

    this.showResults = true;
    
    this.settingsState.trackPage("search");
  }

  async showNotFoundResults(): Promise<void> {
    this.setEventParams();
    this.runnerNum = this.byFace === "true" ? "" : this.number;
    this.runnerFrameValues = "";

    if (this.byFace === "true") {
      this.faceImage = this.searchState.persons.length > 0 ? this.searchState.persons[0].faceUrl : "";
      this.selfieId = this.searchState.selfieId;
      this.personId = this.searchState.persons.length > 0 ? this.searchState.persons[0].personId : this.number;
    } else {
      this.faceImage = "";
      this.selfieId = "";
      this.personId = "";
    }

    await this.settingsState.setMyPhotosLink("");

    this.photos = JSON.stringify([]);
    this.notFound = "true";

    this.showResults = true;

    this.settingsState.trackPage("search");
  }

  async setValuesForFrame(): Promise<void> {
    if (this.byFace === "true") {
      this.runnerNum = '';
      return;
    } else {
      this.runnerNum = this.number;
    }
    
    await this.searchState.clearFound();

    const payload: any = { eventId: this.eventId, search: this.runnerNum };
    await this.searchState.searchQuick(payload);

    const runners: any[] = this.searchState.runners.filter((i: any) => i.number === this.runnerNum);
    if (runners.length == 0 || runners.length > 1) return;

    const value = runners[0];
    const runnerFrameValues: any = {
      competitorId: value.competitorId || "",
    };
    if (value.surname) runnerFrameValues["surname"] = value.surname;
    if (value.firstName) runnerFrameValues["firstName"] = value.firstName;
    if (value.time) runnerFrameValues["time"] = value.time;
    if (value.position) runnerFrameValues["position"] = value.position.toString();
    if (value.distance) runnerFrameValues["distance"] = value.distance;
    this.runnerFrameValues = JSON.stringify(runnerFrameValues);
  }

  async saveRecentSearch(data: { event: any, search: any, date: number }): Promise<void> {
    const itemName = "eventRecentSearch";
    let value: any = localStorage.getItem(itemName)
    if (value) {
      try {
        value = JSON.parse(value);
        value = await this.addSearch(value, data);
        await this.saveRecent(itemName, value);
        return;
      } catch (e) {
        await this.saveFirstRecent(itemName, data);
      }      
    } else {
      await this.saveFirstRecent(itemName, data);
    }
  }

  async addSearch(items: any[], data: { event: any, search: any, date: number }): Promise<any[]> {
    const result: any[] = [];

    let duplicate: boolean = false;

    for (let i = 0; i < items.length; i++) {
      if (items[i].event.eventId === data.event.eventId) {
        
        if (items[i].search.startNumber && data.search.startNumber) {
          if (items[i].search.startNumber === data.search.startNumber) {
            const item = items[i];
            item.date = data.date;
            item.search = data.search;
            result.push(item);
            duplicate = true;
            continue;
          }
          result.push(items[i]);
        } else if (items[i].search.selfieId && data.search.selfieId) {
          if (items[i].search.selfieId === data.search.selfieId) {
            const item = items[i];
            item.date = data.date;
            item.search = data.search;
            result.push(item);
            duplicate = true;
            continue;
          }
          result.push(items[i]);
        } else {
          result.push(items[i]);
        }

      } else {
        
        result.push(items[i]);
      }
    }

    if (!duplicate) {
      result.push(data);
    }

    return result;
  }

  async saveRecent(itemName: string, data: any[]): Promise<void> {
    const items: any[] = await this.getNewest(data);
    localStorage.setItem(itemName, JSON.stringify(items));
  }

  async getNewest(items: any[]): Promise<any[]> {
    let sorted: any[] = items.sort(this.sort);
    sorted.reverse();

    const newest: any[] = [];
    const events: string[] = [];

    for (let i = 0; i < sorted.length; i++) {
      if (events.length == 0) {
        events.push(sorted[i].event.eventId);
        newest.push(sorted[i]);
        continue;
      }

      if (events.length == 1 && events.indexOf(sorted[i].event.eventId) == -1) {
        events.push(sorted[i].event.eventId);
        newest.push(sorted[i]);
        continue;
      }

      if (events.length == 1 && events.indexOf(sorted[i].event.eventId) == 0) {
        const found: any[] = newest.filter((r: any) => r.event.eventId === sorted[i].event.eventId);
        if (found.length < 3) {
          newest.push(sorted[i]);
        }
        continue;
      }

      if (events.length == 2 && events.indexOf(sorted[i].event.eventId) == -1) {
        break;
      }

      if (events.length == 2 && events.indexOf(sorted[i].event.eventId) >= 0) {
        const found: any[] = newest.filter((r: any) => r.event.eventId === sorted[i].event.eventId);
        if (found.length < 3) {
          newest.push(sorted[i]);
        }
        continue;
      }
    }

    return newest;
  }

  sort(a: any, b: any): number {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  }

  async saveFirstRecent(itemName: string, data: { event: any, search: any, date: number }): Promise<void> {
    data.date = Date.now();
    localStorage.setItem(itemName, JSON.stringify([data]));
  }

  getCurrentRecent() {
    let current;
    let eventRecentSearch: any = localStorage.getItem('eventRecentSearch');
    if (eventRecentSearch) {
      eventRecentSearch = JSON.parse(eventRecentSearch);
      if (this.isStartNumber(this.number)) {
        current = eventRecentSearch.find((item: any) => {
          return item.event.eventId == this.event?.eventId
            && item.search.startNumber == this.number
            && item.search.token;
        });
      } else {
        const num: string[] = this.number.split("+");
        const selfieId: string = num[0];
        const personId: string = num[1];
        current = eventRecentSearch.find((item: { search: any; }) => {
          return item.search.selfieId == selfieId
            && item.search.personId == personId
            && item.search.token;
        });
      }
    }
    return current;
  }

  async getNewSearchToken(payload: any): Promise<any> {
    const tokenResult = await this.searchState.getToken(payload);
    if (tokenResult.status !== 200) return null;
    return this.searchState.searchToken;
  }

  async getCart(): Promise<void> {
    await this.cartState.loadCartId();

    if (this.cartState.cartId != "") {
      await this.cartState.getCart({ cartId: this.cartState.cartId });

      if (this.cartState.isCartError) {
        await this.cartState.removeCart();
      }
    }
  }

  async mounted(): Promise<void> {
    this.$store.state.buttonHome = false;
    this.$store.state.buttonBack = true;
    this.$store.state.buttonCart = true;
    this.$store.state.showMenu = false;
    this.$store.state.goBack = "";

    await this.settingsState.setAppBarHidden(false);
    await this.settingsState.setModalActive(false);
    await this.settingsState.setLoadingRoute(true);

    await this.getCart();

    this.backToTop();
    
    if (this.paramId && this.number) {
      
      await this.eventsState.getEventByExternalId(this.paramId);

      const event: any = { ...this.eventsState.event };
      if (event === null) {
        await this.$router.push("/");
        return;
      }

      this.byFace = "false";
      if (this.$store.state.history.length == 0) {
        this.$store.state.history = ["home"];
      }

      await this.eventsState.getEventById(event.eventId);

      this.event = { ...this.eventsState.event };

      this.searchByTag(this.event.eventId, this.number);
    } else if (!this.hasArgs) {
      await this.$router.push("/");
      return;
    } 
  }
}
